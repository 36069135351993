<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
            <dynamic-select
              id="customerName"
              label="Cliente"
              placeholder="Digite o nome do cliente"
              v-model="customer"
              :options="customers"
              :loading="loading.customers"
              @find="findCustomers"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
            <b-form-group
              label="Parceiro de negócio (PN)"
              label-for="searchConsultant"
            > 
              <v-select
                id="searchConsultant"
                multiple
                v-model="consultant"
                :reduce="consultant_list => consultant_list.id"
                :options="consultantList"
                :loading="loading.consultantList"
                :close-on-select="false"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
            
          >
            <b-form-group
              label="Período"
              label-for="searchRangeDate"
            >
              <flat-pickr
                id="searchRangeDate"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <b-form-group
              label="Empresa"
              label-for="searchPartnerCompany"
            >
              <v-select
                id="searchPartnerCompany"
                multiple
                v-model="partnerCompany"
                :reduce="partner_company_list => partner_company_list.id"
                :options="partnerCompanies"
                :loading="loading.partnerCompanies"
                :close-on-select="false"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <b-form-group
              label="Produto"
              label-for="searchProduct"
            >
              <v-select
                id="searchProduct"
                multiple
                v-model="product"
                :reduce="product_list => product_list.key"
                :options="products"
                :loading="loading.products"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <b-form-group
              label="Conciliador do pagamento"
              label-for="searchConciliator"
            >
              <v-select
                id="searchConciliator"
                multiple
                v-model="paymentConciliator"
                :reduce="conciliator_list => conciliator_list.id"
                :options="paymentConciliators"
                :loading="loading.paymentConciliators"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2 text-right">
            <b-button
              variant="outline-primary"
              @click.prevent="search(1)"
              :disabled="loading.search"
            >
              <b-spinner v-if="loading.search" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading.search ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
            &nbsp;
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              :disabled="loading.search"
              v-text="'Limpar'"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas empresas para esta busca"
        :busy="loading.search"
      >
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.consultant_name
            }}</span>
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.customer_name
            }}</span>
          </div>
        </template>
        <!-- Column: Solução Finanaceira -->
        <template #cell(financial_solution_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.financial_solution_name
            }}</span>
          </div>
        </template>
        <!-- Column: Conciliador do pagamento -->
        <template #cell(conciliator)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.conciliator | paymentConciliator
            }}</span>
          </div>
        </template>
        <!-- Column: Parcela -->
        <template #cell(installment)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.installment
            }}</span>
          </div>
        </template>
        <!-- Column: Valor recebido -->
        <template #cell(reconciled_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.reconciled_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Data do contrato -->
        <template #cell(contract_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.contract_date | onlyDate
            }}</span>
          </div>
        </template>
        <!-- Column: Data do pagamento -->
        <template #cell(reconciled_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.reconciled_date | onlyDate
            }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getRangeDates } from "@/helpers/date_picker";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from '@/helpers/todayButton'
import DynamicSelect from '@/modules/shared/components/DynamicSelect'
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BPopover,
    vSelect,
    DynamicSelect,
    flatPickr
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear()

    return { toast: useToast(), skin, actualYear };
  },
  data() {
    return {
      loading: {
        search: false,
        partnerCompanies: false,
        products: false,
        customers: false,
        consultantList: false,
        paymentConciliators: false,
      },
      tableColumns: [
        { key: "consultant_name", label: "PN"},
        { key: "customer_name", label: "Cliente"},
        { key: "financial_solution_name", label: "Solução Financeira"},
        { key: "conciliator", label: "Conciliador do pagamento"},
        { key: "installment", label: "Parcela"},
        { key: "reconciled_amount", label: "Valor recebido"},
        { key: "contract_date", label: "Data do contrato"},
        { key: "reconciled_date", label: "Data do pagamento"}
      ],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      currentPage: 1,
      itemsPerPage: 1,
      consultant: undefined,
      customer: undefined,
      partnerCompany: undefined,
      rangeDate: undefined,
      product: undefined,
      paymentConciliator: undefined
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.CONTRACT_PAYMENTS_SEARCH_RESULT,
      customers: sharedTypes.CUSTOMERS,
      consultantList: sharedTypes.CONSULTANTS,
      partnerCompanies: sharedTypes.PARTNER_COMPANIES,
      products: types.PAYMENT_PRODUCTS,
      paymentConciliators: types.PAYMENT_CONCILIATORS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage
    this.search()
    this.loading.partnerCompanies = true
    this.getPartnerCompanies()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as empresas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.partnerCompanies = false
      })
    this.loading.products = true
    this.getPaymentConciliators()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os conciliadores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.products = false
      })
    this.loading.consultantList = true
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultantList = false
      })
    this.loading.paymentConciliators = true  
    this.getPaymentProducts()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os produtos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.paymentConciliators = false
      })
  },
  methods: {
    ...mapActions({
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getPartnerCompanies: sharedTypes.GET_PARTNER_COMPANIES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getPaymentConciliators: types.GET_PAYMENT_CONCILIATORS,
      getPaymentProducts: types.GET_PAYMENT_PRODUCTS,
      searchContractPayments: types.SEARCH_CONTRACT_PAYMENTS
    }),
    findCustomers(keyword) {
      if(keyword.length < 3) return
      this.loading.customers = true;
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.customers = false;
        });
    },
    search(currentPage) {
      this.loading.search = true;
      const { 
        consultant, 
        customer, 
        product,
        partnerCompany,
        rangeDate,
        paymentConciliator
      } = this;
      let paymentDate = getRangeDates(rangeDate);
      this.searchContractPayments({
        consultant,
        customer,
        product,
        partner_company: partnerCompany,
        payment_date_start: paymentDate.start,
        payment_date_end: paymentDate.end,
        payment_conciliator: paymentConciliator,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os pagamentos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.search = false;
        });
    },
    clearSearch() {
      this.consultant = undefined
      this.customer = undefined
      this.partnerCompany = undefined
      this.rangeDate = undefined
      this.product = undefined
      this.paymentConciliator = undefined
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>